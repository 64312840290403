import { graphql, navigate } from "gatsby"
import React from "react"

import ReactPlayer from "react-player/vimeo"
import { css } from "@emotion/react"
import styled from "@emotion/styled"

import BackArrow from "../assets/svg/arrow-back.svg"

const Wrapper = styled.div`
  svg {
    margin: auto;
    cursor: pointer;
    padding: 40px;
    width: 150px;
    transition: all 1s ease;
    &:hover {
      width: 160px;
    }
  }
`

const Container = styled.div`
  color: #0420bf;
  h2 {
    margin: 0;
    padding-top: 30px;
    text-align: center;
    font-family: "GT-Super-Display";
    font-weight: 700;
    font-size: 3em;
  }
  h3 {
    margin: 0;
    text-align: center;
    font-family: "Syne", Helvetica, sans-serif;
    font-size: 1.25em;
    font-weight: 500;
  }
`

const PlayerContainer = styled.div`
  min-width: 100%;
  position: relative;
  padding-top: 56.25%;
`

export default function GalleryDetails({ data }) {
  const { title, client, vimeo } = data.markdownRemark.frontmatter

  return (
    <Wrapper>
      <Container>
        <BackArrow onClick={() => navigate(-1)} />
        <PlayerContainer>
          <ReactPlayer
            className="react-player"
            css={css`
              position: absolute;
              top: 0;
              left: 0;
            `}
            width="100%"
            height="100%"
            playing={true}
            controls={true}
            playsinline={true}
            url={"https://player.vimeo.com/video/" + vimeo}
          />
        </PlayerContainer>
        <h2>{title}</h2>
        <h3>{client}</h3>
      </Container>
    </Wrapper>
  )
}

export const query = graphql`
  query MyQuery($slug: String) {
    markdownRemark(frontmatter: { slug: { eq: $slug } }) {
      frontmatter {
        title
        client
        vimeo
      }
    }
  }
`
